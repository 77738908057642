@use '@angular/material' as mat;
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$ct-scan-black: (
    50: #e0e0e0,
    100: #b3b3b3,
    200: #808080,
    300: #4d4d4d,
    400: #262626,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #a6a6a6,
    A200: #8c8c8c,
    A400: #737373,
    A700: #666666,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$ct-scan-primary: mat.define-palette($ct-scan-black);
$ct-scan-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ct-scan-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$ct-scan-dark-theme: mat.define-dark-theme(
    $ct-scan-primary,
    $ct-scan-accent,
    $ct-scan-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
.ct-scan-dark-theme {
    @include mat.all-component-themes($ct-scan-dark-theme);

    .mat-drawer-container {
        background: black;
    }

    .mat-drawer {
        background-color: #333333;
    }

    .mat-progress-spinner circle,
    .mat-spinner circle {
        stroke: #007ac2;
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        background-color: #007ac2;
    }

    .mat-toolbar.mat-primary {
        background-color: transparent;
    }

    .mat-tooltip {
        color: #9acb37 !important;
        max-width: max-content;
        margin: 0px;
    }

    .mat-option:hover:not(.mat-option-disabled) {
        background-color: #0f5380;
    }
}
