/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.mat-card {
    text-align: left;
}

.my-toolbar {
    display: flex;
    align-items: center;
    width: 100%;
}

.my-form {
    min-width: 150px;
    max-width: 500px;
    width: 100%;
}

.my-full-width {
    width: 80%;
}

body {
    margin: 0;
}

mat-card {
    max-width: 80%;
    margin: 2em auto;
    text-align: center;
}

mat-toolbar-row {
    justify-content: space-between;
}

.done {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.content-center {
    text-align: center;
}

mat-form-field {
    display: block;
}

ul {
    text-align: left;
}

.validation .warning {
    float: right;
    color: #f96021;
}

.mat-drawer-container {
    background: #939393;
}

mat-card-actions {
    margin-left: 24px !important;
}

.dash-tooltip-class {
    font-size: 16px;
}

.mat-tooltip {
    color: yellow !important;
    font-size: 16px;
}

html,
body {
    height: 100%;
}
